import TableCell from '@mui/material/TableCell';
const DateCleaner = ({originalDate}) => {
    const date = new Date(originalDate)
    const cleanedDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
    return(
        <>
        <TableCell align="right">{cleanedDate}</TableCell>
        </>
    )   
}
export default DateCleaner