import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom"
const Nav = () => {
    const navigate = useNavigate()
    const Logout = () => {
        navigate("/");
        localStorage.removeItem("token");
    }
    const TableRedirect = () => {
        navigate("/discord-stats/table")
    }
    const Visualizer = () => {
        navigate("/discord-stats/visualize")
    }
    const Announcement = () => {
        navigate("/discord-stats/announcement")
    }
    const UserPanel = () => {
        navigate("/discord-stats")
    }
    const Whitelist = () => {
        navigate("/discord-stats/whitelist")
    }
    return (
        <>
        <Button onClick={UserPanel}>User Panel</Button>
        {/* <Button onClick={TableRedirect}>Table</Button> */}
        <Button onClick={Visualizer}>Visualizer</Button>
        <Button onClick={Announcement}>Announcement</Button>
         <Button onClick={Whitelist}>Whitelist</Button>
        <Button onClick={Logout}>Logout</Button>
        </>
    )
}
export default Nav;