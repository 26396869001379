import './App.css';
import Login from "./Routes/Login/Login"
function App() {
  return (
    <div className="App">
      <Login/>
    </div>
  );
}

export default App;
