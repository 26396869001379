import { gql, useQuery } from "@apollo/client";
import MaxResponses from "./Components/Responses/MaxResponses"
import { useState, useEffect } from "react"
const UserPanel = () => {
    const [channelsList, setChannelsList] = useState([])
    useEffect(() => {
        const FetchChannelsList = async () => {
            const serverId = process.env.REACT_APP_SERVER_ID
            const url = `${process.env.REACT_APP_BACKEND}/channel_list`
            
            const data = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    "jwt": localStorage.getItem("token"),
                    "guildId": serverId
                })
            })
            
            const json = await data.json()
            
            setChannelsList(json);
        }
        FetchChannelsList().catch(console.error)
    }, [])
    const userViewQuery = gql`
    query($serverId: String!, $jwt: String!, $indexName: String!){
        users(serverId: $serverId, jwt: $jwt, indexName: $indexName){
            userName
            userId
            keys
            userMessages
            userFromAddress,
            messagesCount,
            maxMessages,
            minMessages,
            serverJoinDate,
            boostedStatus,
            boostedSince,
            userAccountCreationDateIso
        }
    }
    `
    const serverId = process.env.REACT_APP_SERVER_ID
    const { data, loading, error } = useQuery(userViewQuery, {
        variables: {
            serverId,
            jwt: localStorage.getItem("token"),
            indexName: process.env.REACT_APP_INDEX_NAME
        },
    });
    if (loading) {
    return <p>Loading Graphql data...</p>
  }
  // eslint-disable-next-line no-return-assign
  if (error) return `Submission error! ${error.message}`;
  
    return(
        <div>
            <MaxResponses data={data} channelsList={channelsList}/>
        </div>
    )
}
export default UserPanel