/* eslint-disable no-console */
import escapeHtml from "escape-html";
import { Text } from "slate";

const TextSerializer = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold)
      string = `**${string}**`;
    else if(node.underline)
      string = `__${string}__`
    else if(node.italic)
      string = `*${string}*`
    else if(node.formattedlistbulleted)
      string = `\n •${string}\n`
    return string;
  }
  
  const children = node.children.map((n) => TextSerializer(n)).join("");
  switch (node.type) {
    case "quote":
      let quoteblock = "```"
      return `${quoteblock}${children}${quoteblock}`;
    case "paragraph":
      return `${children}`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "bold":
      return `**${children}**`
    case "italic":
      return `*${children}*`
    case "underline":
      return `__${children}__`
    case "formattedlistbulleted":
      return `\n• ${children}\n`
    default:
      return children;
  }
};
export default TextSerializer;
