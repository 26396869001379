import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Nav from "../Nav/nav";
import SlateEditor from "./editor";
import TextSerializer from "./textSerializer";
const Announcement = () => {
    const [discordMessage, setDiscordMessage] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
    ]);
    const [discordMessageFinal, setDiscordMessageFinal] = useState("");
    const SendMessage = async() => {
        const baseUrl = process.env.REACT_APP_BACKEND;
        const url = `${baseUrl}/channel-announcement`;
        const channelId = process.env.REACT_APP_FURY_ANNOUNCEMENTS;
        const request = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                "message": discordMessageFinal,
                "channelId": channelId,
                "jwt": localStorage.getItem("token")
            })
        })
    }
    useEffect(() => {
        setDiscordMessageFinal(TextSerializer(discordMessage[0]))
    }, [discordMessage])
    
    return(
        <>
         <Nav></Nav>
         <div style={{textAlign: "center"}}>
         <SlateEditor value={discordMessage} setValue={setDiscordMessage}/>
         <Button onClick={SendMessage}>Submit</Button>
         </div>
        </>
    )
}
export default Announcement