import MessageHistoryTable from "./MessageHistoryTable"
import Button from '@mui/material/Button';
const MessagesHandler = ({ userMessages, setVisibility}) =>{
        return(
            <div>
                <MessageHistoryTable tableContent={userMessages}/>
                <Button onClick={() => setVisibility(false)}>Close</Button>
            </div>
        )
}
export default MessagesHandler