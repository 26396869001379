import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import './index.css';
import App from './App';
import UserPanel from './Routes/UserPanel/UserPanel';
import WhiteListHandler from './Routes/Whitelist/whitelisthandler';
// import HelpfulOptions from "../src/Routes/HelpfulResponses/helpfuloptions"
import GraphPage from './Routes/Graph/graph';
import Announcement from './Routes/Announcement/announcement';
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
});

const wsLink = new GraphQLWsLink(createClient({
  url: `wss://${process.env.REACT_APP_WEBSOCKET_URL}/graphql`,
}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})
ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
            <Routes>
              <Route path="/discord-stats" element={<UserPanel />} />
              <Route path="/" element={<App />} />
              {/* <Route path="/discord-stats/table" element={<HelpfulOptions />} /> */}
              <Route path="/discord-stats/whitelist" element={<WhiteListHandler />} />
              <Route path="/discord-stats/visualize" element={<GraphPage />} />
              <Route path="/discord-stats/announcement" element={<Announcement />} />
            </Routes>
        </BrowserRouter>,
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();