/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
import { useState } from "react"
import MessagesHandler from "./MessagesHandler"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DateCleaner from "./DateCleaner";
import BanModal from "./banModal";
import KickModal from "./kickModal";
const TableContents = ({ data, setCsvData, csvData, setVisibility, visibility}) => {
    const [tableContent, setTableContent] = useState({})
    const [kickOpen, setKickOpen] = useState(false);
    const handleKickOpen = () => setKickOpen(true);
    const handleKickClose = () => setKickOpen(false);
    const [kickedUser, setKickedUser] = useState({})
    

    const [banOpen, setBanOpen] = useState(false);
    const handleBanOpen = () => setBanOpen(true);
    const handleBanClose = () => setBanOpen(false);
     const [bannedUser, setBannedUser] = useState({})
    const MessageHistory = (event, userMessages, sender) => {
        setVisibility(true)
        setTableContent({
            "messages": userMessages,
            "sender": sender
        })
    }
    
    const CsvStore = (event, userData) => {
        const checked = event.target.checked;
        if(checked === true){
            const data = {
                "userID": userData.userId,
                "userName": userData.userName,
                "fromAddress": userData.userFromAddress,
                "furyAccessKeys": userData.keys
            }
            
            setCsvData(csv => csv.concat(data));
        }
        else{
            const mutatedArray = [...csvData];
            mutatedArray.splice(csvData.indexOf(userData.userId), 1);
            setCsvData(mutatedArray)
        }
    }
    const KickUserClick = (userId, messagesCount) => {
        /** Shows up as the object itself?*/
        setKickedUser({
            "userId": userId,
            "messagesCount": messagesCount
        })
        handleKickOpen()
    }
    const BanUserClick = (userId, messagesCount) => {
        setBannedUser({
            "userId": userId,
            "messagesCount": messagesCount
        })
        handleBanOpen()
    }
    return(
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>UserID</TableCell>
                        <TableCell align="right">Username</TableCell>
                        <TableCell align="right">Wallet Address</TableCell>
                        <TableCell align="right">Message History</TableCell>
                        <TableCell align="right">Fury Access Keys</TableCell>
                        <TableCell align="right">Messages Count</TableCell>
                        <TableCell align="right">Kick User</TableCell>
                        <TableCell align="right">Ban User</TableCell>
                        <TableCell align="right">Has Boosted?</TableCell>
                        <TableCell align="right">Date of first boost</TableCell>
                        <TableCell align="right">Account Creation</TableCell>
                        {/* <TableCell align="right">Helpful Messages</TableCell> */}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((userData) =>
                        <>
                        <TableRow
                        key={userData.userId}
                        sx={{ '&:last-child td &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {userData.userId}
                            </TableCell>
                            <TableCell align="right">{userData.userName}</TableCell>
                            <TableCell align="right">{userData.userFromAddress}</TableCell>
                            {userData.userMessages.length > 0 ? <TableCell align="right"><Button type={"submit"} onClick={event => MessageHistory(event, userData.userMessages, userData.userName)} className={userData.userId + "-button"}>Message History</Button></TableCell> : <TableCell align="right"><Button disabled={true}>Messages</Button></TableCell>}
                            <TableCell align="right">{userData.keys}</TableCell>
                            <TableCell align="right">{userData.messagesCount}</TableCell>
                            <TableCell align="right"><Button type={"submit"} onClick={event => KickUserClick(userData.userId, userData.messagesCount)}>Kick</Button></TableCell>
                            <TableCell align="right"><Button type={"submit"} onClick={event => BanUserClick(userData.userId, userData.messagesCount)}>Ban</Button></TableCell>
                            <TableCell align="right">{userData.boostedStatus}</TableCell>
                            {userData.boostedSince === "N/A" ? <TableCell align="right">N/A</TableCell> : <DateCleaner originalDate={userData.boostedSince}/>}
                            <DateCleaner originalDate={userData.userAccountCreationDateIso}/>
                            {/* <TableCell align="right"><input type="checkbox" onChange={e => CsvStore(e, userData )}/></TableCell> */}
                        </TableRow>
                        </>
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            { visibility ? <MessagesHandler userMessages={tableContent} setVisibility={setVisibility}/> : null}
            { kickOpen ? <KickModal userId={kickedUser.userId} messagesCount={kickedUser.messagesCount} open={handleKickOpen} onClose={handleKickClose} /> : null}
            { banOpen ? <BanModal userId={bannedUser.userId} messagesCount={bannedUser.messagesCount} open={handleBanOpen} onClose={handleBanClose} /> : null}
        </div>
    )
}
export default TableContents