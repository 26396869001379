import { gql, useQuery } from "@apollo/client";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
const Visualizer = ({ indexName, date, titleData }) => { 
    const graphViewQuery = gql`
    query($jwt: String!, $index: String!, $firstDay: String!, $secondDay: String!){
        graph(jwt: $jwt, index: $index, firstDay: $firstDay, secondDay: $secondDay){
            graphData
        }
    }
    `
    // const serverId = process.env.REACT_APP_SERVER_ID
    const { data, loading, error } = useQuery(graphViewQuery, {
        variables: {
            jwt: localStorage.getItem("token"),
            index: indexName,
            firstDay: date.firstDay,
            secondDay: date.secondDay
        },
        pollInterval: 500,
    });
    if (loading) {
    return <p>Loading Graphql data...</p>
  }

  // eslint-disable-next-line no-return-assign
  if (error) return `Submission error! ${error.message}`;
  
  return(
      <>
      <div
      style={{
        padding: "10px",
        boxSizing: "border-box",
        width: 400,
        height: 400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      
      <ResponsiveContainer width='100%' height='100%' style={{
        padding: "10px",
        boxSizing: "border-box",
        width: 400,
        height: 400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <BarChart
      data={data.graph[0].graphData}
      margin={{
        top: 5,
        right: 20,
        left: 20,
        bottom: 5
      }}
      barSize={30}
      textAnchor="middle"
    >
      <XAxis dataKey="key" tick={false}/>
      <YAxis />
      <Tooltip />
      <Legend />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar dataKey="wallets" fill="#8884d8" background={{ fill: "#eee" }} />
    </BarChart>
    </ResponsiveContainer>
    </div>
    </>
  )
}
export default Visualizer