/* eslint-disable array-callback-return */
import { gql, useQuery } from "@apollo/client";
import {useState, useEffect } from "react";
import Whitelist from "./whitelist";
const WhiteListHandler = () => {
    const [roles, setRoles] = useState([])
    useEffect(() => {
        const FetchRolesList = async () => {
            const serverId = process.env.REACT_APP_SERVER_ID;
            const url = `${process.env.REACT_APP_BACKEND}/roles_lookup`
            const data = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    "jwt": localStorage.getItem("token"),
                    "serverId": serverId
                })
            })
            const json = await data.json()
            setRoles(json);
        }
        FetchRolesList().catch(console.error)
    }, [])
    
    const userViewQuery = gql`
    query($serverId: String!, $jwt: String!, $indexName: String!){
        users(serverId: $serverId, jwt: $jwt, indexName: $indexName){
            userName
            userId
            userFromAddress
            userAccountCreationDate
            serverJoinDate
            userAccountCreationDateIso
            roles
            boostedSince
            boostedStatus
        }
    }
    `
    const serverId = process.env.REACT_APP_SERVER_ID
    const { data, loading, error } = useQuery(userViewQuery, {
        variables: {
            serverId,
            jwt: localStorage.getItem("token"),
            indexName: process.env.REACT_APP_INDEX_NAME
        },
    });
    if (loading) {
        return <p>Loading Graphql data...</p>
    }
  // eslint-disable-next-line no-return-assign
  if (error) return `Submission error! ${error.message}`;
  

return(
    <>
    <Whitelist roles={roles} data={data} />
    </>
)
}
export default WhiteListHandler