import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Nav from '../Nav/nav';
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import { useState } from "react";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Whitelist = ({data, roles}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedRole, setSelectedRole] = useState(roles[0].name)
    const CsvExporter = (data) => {
    const modifiedData = data.users.slice(0, data.users.length-1);
    
    let csvDataStorage = []
    for(let user = 0; user < modifiedData.length; user++){
        if(modifiedData[user].roles.includes(selectedRole)){
            const serverJoinDate = new Date(modifiedData[user].serverJoinDate);
            const accountCreationDate = new Date(modifiedData[user].userAccountCreationDateIso)
            const firstBoostDate = new Date(modifiedData[user].boostedSince)
            const boostedStatus = modifiedData[user].boostedStatus
            const data = {
                    "user id": String(modifiedData[user].userId),
                    "user name": modifiedData[user].userName,
                    "account creation date": accountCreationDate,
                    "server joined date": serverJoinDate,
                    "First boost date": firstBoostDate,
                    "Boosted Status": boostedStatus
                }
                csvDataStorage.push(data)
        }
        else{
            continue
        }
    }
    const today = new Date()
    const todayStringUSA = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${String(today.getFullYear())}`
    
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: `Discord whitelist report ${todayStringUSA} `,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(csvDataStorage);
    }
    return(
        <>
        <Nav></Nav>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <label>Select role to filter users by</label>
            <br/>
            <select onChange={(e) => setSelectedRole(e.target.value)} value={selectedRole} defaultValue={{ label: "Select Role", value: 0 }}>
                        {roles.map((role) =>
                            <option value={role.name}>{role.name}</option>
                        )}
                    </select>
            <Button onClick={(e) => CsvExporter(data)}>Export</Button>
            <Button onClick={handleClose}>Close</Button>
            </Box>
        </Modal>
        <Button onClick={handleOpen}>Export to CSV</Button>
        </>
    )
}
export default Whitelist;