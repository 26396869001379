/* eslint-disable array-callback-return */
import Handler from "./Handler";

const MaxResponses = ({data, channelsList}) => {
    
    
    return(
        <div>
           <Handler data={data} channelsList={channelsList}/>
        </div>
    )
}
export default MaxResponses