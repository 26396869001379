import { useState } from "react"
import moment from "moment";
import Visualizer from "./visualizer";
import Nav from "../../Nav/nav";
const EmojiOptionsSelector = () => {
    const [role, setRole] = useState("Moderator");
    const today = new Date()
    const todayString = `${String(today.getFullYear())}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
    const todayStringUSA = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${String(today.getFullYear())}`
    const [todayDate, setToday] = useState(todayString);
    const [firstDay, setFirstDay] = useState(todayString);
    const [secondDay, setSecondDay] = useState(todayString);
    const [titleData, setTitleData] = useState(`${todayStringUSA} to ${todayStringUSA}`);
    const [date, setDate] = useState({
      "firstDay": firstDay,
      "secondDay": secondDay
    })
    const SetRoleName = (event) => {
      setRole(event.target.value);
    }
    const lastday = (y,m) => {
      const day = new Date(y, m +1, 0)
      return `${String(day.getFullYear())}-${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`
    }
    const firstday = (y,m) => {
      const day = new Date(y, m, 1)
      return `${String(day.getFullYear())}-${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`
    }
    const lastdayUSA = (y, m) => {
      const day = new Date(y, m + 1, 0)
      return `${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}-${String(day.getFullYear())}`
    }
    const firstdayUSA = (y, m) => {
      const day = new Date(y, m, 1)
      return `${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}-${String(day.getFullYear())}`
    }
    const SetTimeStamp = (event) => {
      const date = event.target.value;
      if(date === "Today"){
        let dateData = {
          "firstDay": firstDay,
          "secondDay": secondDay
        }
        setDate(dateData)
        setTitleData(`${todayStringUSA} to ${todayStringUSA}`);
      }
      else if(date === "This week"){
        const today = new Date();
        const firstDay = new Date(today.setDate((today.getDate() - today.getDay()) + 1));
        const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
        const firstDayString = `${String(firstDay.getFullYear())}-${String(firstDay.getMonth() + 1).padStart(2, '0')}-${String(firstDay.getDate()).padStart(2, '0')}`
        const lastDayString = `${String(lastDay.getFullYear())}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`
        let dateData = {
          "firstDay": firstDayString,
          "secondDay": lastDayString
        }
        const firstUSA = `${String(firstDay.getMonth() + 1).padStart(2, '0')}-${String(firstDay.getDate()).padStart(2, '0')}-${String(firstDay.getFullYear())}`
        const secondUSA = `${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}-${String(lastDay.getFullYear())}`
        setDate(dateData);
        setTitleData(`${firstUSA} to ${secondUSA}`)
      }
      else if(date === "This month"){
        const today = new Date();
        const firstDayDate = firstday(today.getFullYear(), today.getMonth())
        const lastDayDate = lastday(today.getFullYear(), today.getMonth())
        let dateData = {
          "firstDay": firstDayDate,
          "secondDay": lastDayDate
        }
        setDate(dateData);
        const firstDay = firstdayUSA(today.getFullYear(), today.getMonth())
        const secondDay = lastdayUSA(today.getFullYear(), today.getMonth())
        setTitleData(`${firstDay} to ${secondDay}`);
      }
      else if(date === "Past 15 minutes"){
        const today = new Date()
        
        const fifthteenBefore = moment(today).subtract(15, "m").toISOString()
        
        const dateData = {
          "firstDay": fifthteenBefore,
          "secondDay": today.toISOString()
        }
        setDate(dateData)
        setTitleData(`${todayStringUSA} during the past 15 minutes`);
      }
      else if(date === "Past 30 minutes"){
        const today = new Date()
        
        const thirtyBefore = moment(today).subtract(30, "m").toISOString()
        
        const dateData = {
          "firstDay": thirtyBefore,
          "secondDay": today.toISOString()
        }
        setDate(dateData)
        setTitleData(`${todayStringUSA} during the past 30 minutes`);
      }
      else if(date === "Past 90 days"){
        const today = new Date()
        
        const last2Months = moment(today).subtract(3, "months").toISOString()
        const last2MonthsDate = moment(today).subtract(3, "months").toDate();
        const last2MonthsString = `${String(last2MonthsDate.getMonth() + 1).padStart(2, '0')}-${String(last2MonthsDate.getDate()).padStart(2, '0')}-${String(last2MonthsDate.getFullYear())}`
        const dateData = {
          "firstDay": last2Months,
          "secondDay": today.toISOString()
        }
        setDate(dateData)

        setTitleData(`${last2MonthsString} - ${todayStringUSA}`);
      }
      else if(date === "Last 1 Year"){
        const today = new Date()
        
        const lastYear = moment(today).subtract(1, "years").toISOString()
        const lastYearDate = moment(today).subtract(1, "years").toDate();
        const lastYearString = `${String(lastYearDate.getMonth() + 1).padStart(2, '0')}-${String(lastYearDate.getDate()).padStart(2, '0')}-${String(lastYearDate.getFullYear())}`
        const dateData = {
          "firstDay": lastYear,
          "secondDay": today.toISOString()
        }
        setDate(dateData)
        setTitleData(`${lastYearString} - ${todayStringUSA}`);
      }
    }
    return(
        <>
        <select onChange={SetRoleName} value={role}>
        <option value="Moderator">Moderator</option>
      </select>
      <select onChange={SetTimeStamp}>
        <option value="Today">Today</option>
        <option value="This week">This week</option>
        <option value="This month">This month</option>
        <option value="Past 90 days">Past 90 days</option>
        <option value="Past 15 minutes">Past 15 minutes</option>
        <option value="Past 30 minutes">Past 30 minutes</option>
        <option value="Last 1 Year">Last 1 Year</option>
      </select>
      <Visualizer roles={role} date={date} titleData={titleData}/>
      </>
    )
}
export default EmojiOptionsSelector