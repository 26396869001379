import "./Login.css"
import { useState } from "react";
import { useNavigate } from "react-router-dom"
import { ethers } from "ethers";
const Login = () =>{
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate()
  const metamaskLogin = async() => {
      setDisabled(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      // Prompt user for account connections
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      // All properties on a domain are optional
      const domain = {
        name: 'Tribe Signin',
        version: '1',
        chainId: process.env.REACT_APP_CHAIN_ID,
        verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC'
      };

      // The named list of all type definitions
      const types = {
        Person: [
            { name: 'name', type: 'string' },
            { name: 'wallet', type: 'string' },
        ],
        Signer : [
          { name:  "User", type: "Person"}
        ]
      };

      // The data to sign
      const value = {
        User: {
            name: 'Tribe',
            wallet: await signer.getAddress()
        }
      };
      const jwtSignature = await signer._signTypedData(domain, types, value);
      localStorage.setItem("signature", jwtSignature)
      const baseUrl = process.env.REACT_APP_BACKEND
      const jwtTokenUrl = baseUrl + "/get_jwt"
      
      const body = {
          "signature": jwtSignature
      }
      
      const request = await fetch(jwtTokenUrl, {
        method: "POST",
        body: JSON.stringify(body)
      })
      const response = await request.json()
      
      if(request.status === 200){
        localStorage.setItem("token", response["jwt"])
        navigate("/discord-stats")
      }

  }
  return (
    <div className="Login">
      <button disabled={disabled} onClick={metamaskLogin}>Connect</button>
    </div>
  );
}

export default Login;
