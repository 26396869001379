/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import TableContents from "../MessageHistory/TableContents"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ExportToCsv } from 'export-to-csv';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'scroll',
};

const Handler = ({ data, channelsList}) =>{
    const modifiedData = data.users.slice(0, data.users.length-1);
    const [maxMessages, setMaxMessages] = useState(data.users.slice(-1)[0].maxMessages);
    const [filteredUsers, setFilteredUsers] = useState(modifiedData)
    const [users, setUsers] = useState(modifiedData);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [keyCount, setKeyCount] = useState(0)
    const [visibility, setVisibility] = useState(true);
    const [csvData, setCsvData] = useState([])
    const [rank, setRank] = useState(0);
    const [response, setResponse] = useState("all");
    const [boostedTimeRange, setBoostedTimeRange] = useState("");
    const [accountCreationTimeRange, setAccountCreationTimeRange] = useState("");
    
    const [channel, setChannel] = useState(channelsList[0].name);
    const [messageTableVisibility, setMessageTableVisibility] = useState(false)
    const [enabledOptions, setEnabledOptions] = useState(true);
    
    const navigate = useNavigate()
    const filterData = () =>{
        const results = []
        users.filter((user) => {
            
            if(response === "all"){
                if(user.keys.toString() === keyCount.toString()){
                    results.push(user)
                }
            }
            else if(response === "wallets2discord"){
                let boostedDate = new Date(user.boostedSince)
                let finalBoostedDate = (boostedDate.getMonth() + 1) + "/" + boostedDate.getDate() + "/" + boostedDate.getFullYear()
                let accountCreationDate = new Date(user.userAccountCreationDateIso)
                let finalAccountCreationDate = (accountCreationDate.getMonth() + 1) + "/" + accountCreationDate.getDate() + "/" + accountCreationDate.getFullYear()
                    if(user.keys.toString() === keyCount.toString() && user.messagesCount === maxMessages.toString() && user.userFromAddress !== "N/A" &&  boostedTimeRange === finalBoostedDate && accountCreationTimeRange === finalAccountCreationDate){
                        let messages = user.userMessages.filter(message => message.channel_name === channel)
                        results.push({
                            "boostedSince": user.boostedSince,
                            "boostedStatus": user.boostedStatus,
                            "keys": user.keys,
                            "maxMessages": user.maxMessages,
                            "minMessages": user.minMessages,
                            "serverJoinDate": user.serverJoinDate,
                            "userAccountCreationDateIso": user.userAccountCreationDateIso,
                            "userFromAddress": user.userFromAddress,
                            "userMessages": messages,
                            "userName": user.userName,
                            "messagesCount": user.messagesCount
                        })
                    }
            }
            else if(response === "discord"){
                let boostedDate = new Date(user.boostedSince)
                let finalBoostedDate = (boostedDate.getMonth() + 1) + "/" + boostedDate.getDate() + "/" + boostedDate.getFullYear()
                let accountCreationDate = new Date(user.userAccountCreationDateIso)
                let finalAccountCreationDate = (accountCreationDate.getMonth() + 1) + "/" + accountCreationDate.getDate() + "/" + accountCreationDate.getFullYear()

                if(user.keys.toString() === keyCount.toString() && user.messagesCount === maxMessages.toString() && user.userFromAddress === "N/A" && boostedTimeRange === finalBoostedDate && accountCreationTimeRange === finalAccountCreationDate){
                    let messages = user.userMessages.filter(message => message.channel_name === channel)
                    results.push({
                        "boostedSince": user.boostedSince,
                        "boostedStatus": user.boostedStatus,
                        "keys": user.keys,
                        "maxMessages": user.maxMessages,
                        "minMessages": user.minMessages,
                        "serverJoinDate": user.serverJoinDate,
                        "userAccountCreationDateIso": user.userAccountCreationDateIso,
                        "userFromAddress": user.userFromAddress,
                        "userMessages": messages,
                        "userName": user.userName,
                        "userId": user.userId,
                        "messagesCount": user.messagesCount
                    })
                    }
            }
        })
    
    
    let rankedResults = []
    /*
    Safe guards against an undefined that can occur from the rank being 0,
    and results being undefined 
    */
    if((rank - 1) === 0 && results.length >= 1)
        rankedResults.push(results[0]);
    else if((rank -1) >= 1 && results.length >= 1)
        rankedResults = results.slice(0, (rank - 1))
    
    
    setFilteredUsers(rankedResults)
    if(Object.keys(rankedResults).length <= 0)
        setVisibility(false);
    else
    {
        setVisibility(true);
        setMessageTableVisibility(false)
    }
    handleClose()
    }
    
    const Logout = () => {
        navigate(-1);
        localStorage.removeItem("token");
    }
    const TableRedirect = () => {
        navigate("/discord-stats/table")
    }
    const Visualizer = () => {
        navigate("/discord-stats/visualize")
    }
    const Announcement = () => {
        navigate("/discord-stats/announcement")
    }
    const Whitelist = () => {
        navigate("/discord-stats/whitelist")
    }
    const SetResponse = (event) => {
        const keyword = event.target.value
        if(keyword === "all")
        {
            setResponse(keyword)
            setEnabledOptions(true);
        }
        else if(keyword === "wallets2discord"){
            setResponse(keyword)
            setEnabledOptions(false);
        }
        else if(keyword === "discord"){
            setResponse(keyword)
            setEnabledOptions(false);
        }
            
    }
    return(
        <div>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Filter user by Criteria
                </Typography>
                <label htmlFor="keyAmount">How many keys should a user have?</label>
                <br />
                <input type="number" id="keyAmount" name="keyAmount" onChange={(event) => setKeyCount(event.target.value)} min="1" max="5"></input>
                <br />
                <label htmlFor="keyAmount">How many top response users will be selected?</label>
                <input type="number" id="messagesAmount" name="messagesAmount"  onChange={(event) => setRank(event.target.value)}></input>
                <br />
                <label>What users will be included in the table?</label>
                <select onChange={SetResponse} value={response}>
                    <option value="all">All</option>
                    <option value="wallets2discord">Discord users with wallets</option>
                    <option value="discord">Discord users without wallets</option>
                </select>
                <br />
                <label htmlFor="boostedTime">What date should the boost be from?</label>
                <input type="datetime-local" id="boostedTime" name="keyAmount" disabled={enabledOptions} onChange={(event) => {
                    let date = new Date(event.target.value)
                    let finalDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
                    setBoostedTimeRange(finalDate)}
                    }></input>
                <br/>
                <label htmlFor="accountCreation">What date should the accounts fall within?</label>
                <input type="datetime-local" id="accountCreation" name="accountCreation" disabled={enabledOptions} onChange={(event) => {
                    let date = new Date(event.target.value)
                    let finalDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
                    setAccountCreationTimeRange(finalDate)}
                    }></input>
                <br/>
                <select onChange={(e) => setChannel(e.target.value)} value={channel} disabled={enabledOptions}>
                    {channelsList.map((channel) =>
                         <option value={channel.channel_name}>{channel.name}</option>
                    )}
                </select>
                <br/>
                <Button onClick={filterData}>Submit Data</Button>
                <Button onClick={handleClose}>Close</Button>
            </Box>
            </Modal>
            
            <Button onClick={handleOpen}>Filter Data</Button>
            <Button onClick={Visualizer}>Visualizer</Button>
            <Button onClick={Announcement}>Announcement</Button>
            <Button onClick={Whitelist}>Whitelist</Button>
            <Button onClick={Logout}>Logout</Button>
            {visibility ? <TableContents data={filteredUsers} setCsvData={setCsvData} csvData={csvData} setVisibility={setMessageTableVisibility} visibility={messageTableVisibility}/> : null}
            </div>
    )
}
export default Handler