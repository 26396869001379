import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DateCleaner from './DateCleaner';

const MessageHistoryTable = ({tableContent}) => {
    
    const sender = tableContent.sender;
    return(
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="right">Message</TableCell>
                        <TableCell align="right">Date Posted</TableCell>
                        <TableCell align="right">Original Author</TableCell>
                        <TableCell align="right">Original Message</TableCell>
                        <TableCell align="right">Sender</TableCell>
                        <TableCell align="right">Channel Name</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {tableContent.messages.map((message, index) => 
                        <TableRow
                        key={message.message_id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {index}
                        </TableCell>
                        <TableCell align="right">{message.content}</TableCell>
                        <DateCleaner originalDate={message.timestamp}/>
                        <TableCell align="right">{message.original_authors}</TableCell>
                        <TableCell align="right">{message.original_message}</TableCell>
                        <TableCell align="right">{sender}</TableCell>
                        <TableCell align="right">{message.channel_name}</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
        </TableContainer>
    )
}
export default MessageHistoryTable