import IconButton from '@mui/material/IconButton';
import { FormatListBulleted } from "@mui/icons-material";
import CustomEditor from '../toolbar-actions';
const FormattedListBulleted = ({editor}) => {
    return(
         <IconButton aria-label="format-list-bulleted" onClick={(event)=> {
            event.preventDefault();
            CustomEditor.toggleFormatListBulleted(editor);
        }}>
            <FormatListBulleted />
        </IconButton>
    )
}
export default FormattedListBulleted