import OptionsSelector from "./key_visualizer/keyselector";
import EmojiOptionsSelector from "./emoji_graph/emoji_selector";
const GraphPage = () => {
    return(
        <>
            <OptionsSelector /> 
            <EmojiOptionsSelector />
        </>
    )
}
export default GraphPage