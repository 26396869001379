/* eslint-disable no-restricted-globals */
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box';
import { useState } from "react";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'scroll',
};
const kickUser = async(event, reason, userId, messageAmount, onClose) => {
        const baseUrl = process.env.REACT_APP_BACKEND;
        const url = `${baseUrl}/kick-user`;
        const serverId = process.env.REACT_APP_SERVER_ID
        const request = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                "serverId": serverId,
                "userId": userId,
                "jwt": localStorage.getItem("token"),
                "reason": reason,
                "messageAmount": messageAmount,
                "indexName": process.env.REACT_APP_INDEX_NAME
            })
        })
        onClose()
    }
const KickModal = ({userId, messagesCount, open, onClose}) => {
        const [kickReason, setKickReason] = useState("");
        return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <textarea id="kick-reason"  rows="4" cols="50" onChange={(e) => setKickReason(e.target.value)} />
                <Button onClick={() => kickUser(event, kickReason, userId, messagesCount, onClose)}>Submit Reason</Button>
                <Button onClick={() => onClose()}>Close</Button>
            </Box>
            </Modal>
        )
    }
export default KickModal