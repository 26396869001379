import Bold from './toolbar-icons/bold';
import Italic from './toolbar-icons/italic';
import Underline from './toolbar-icons/underline';
import FormattedListBulleted from './toolbar-icons/formattedBulletList';
const ToolBarIcons = ({editor}) => {
    return(
        <div className="editor-shortcuts">
        <Bold editor={editor} />
        <Italic editor={editor} />
        <Underline editor={editor} />
        <FormattedListBulleted editor={editor} />
        </div>
    )
}
export default ToolBarIcons